import React from "react";
import Iframe from "../components/Iframe";

const Var = () => {
  return (
    <>
      <img
        alt={"build-badge"}
        src={
          "https://codebuild.eu-north-1.amazonaws.com/badges?uuid=eyJlbmNyeXB0ZWREYXRhIjoiL2w5RGJMaHVoTkYxaUhQbDQrRFZlWkZhNDRFWWcyUFVwWjNScDhyaXNiVUxDSDhVaVMyZkdpM21lYkJKM1VOSTQ5ZzVrem05Si9mWTlxNHNIdlJEOUxzPSIsIml2UGFyYW1ldGVyU3BlYyI6IjZDbFMzLzRuNzNLNCszaG8iLCJtYXRlcmlhbFNldFNlcmlhbCI6MX0%3D&branch=master"
        }
      />
      <Iframe />
    </>
  );
};

export default Var;
