import React, { Component } from "react";
import styled from "styled-components";

class Iframe extends Component {
  render() {
    return (
      <IframeContainer>
        <StyledIframe
          src={"https://snrn.se/caesar/index.html"}
          title={"Game"}
          {...this.props}
          frameBorder={"0"}
          width={"50%"}
          height={"50%"}
        />
      </IframeContainer>
    );
  }
}

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
  top: 0;
  left: 0;
  position: absolute;
`;

const IframeContainer = styled.div`
  width: 100%;
  height: 0;
  border: 0;
  position: relative;
  padding-bottom: 51%;
`;

export default Iframe;
